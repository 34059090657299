var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("제품 목록")]), _c('v-spacer'), _c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "secondary",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        _vm.showSearch = !_vm.showSearch;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-filter-variant")]), _vm._v("검색필터")], 1)], 1)], 1), _c('v-expand-transition', [_c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showSearch,
      expression: "showSearch"
    }],
    staticClass: "elevation-1 mx-auto"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("검색필터")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.searchKeys,
      "label": "기간별",
      "hide-details": "",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "검색어",
      "persistent-placeholder": "",
      "hide-details": "",
      "disabled": !_vm.filter.searchKey
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "rounded": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        (_vm.page = 1) && _vm.search();
      }
    }
  }, [_c('v-icon', {
    staticClass: "mt-1 mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-magnify")]), _vm._v("검색")], 1)], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "elevation-1 mt-4",
    attrs: {
      "items": _vm.products,
      "headers": _vm.productHeaders,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.no`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.totalCount - _vm.products.indexOf(item) - (_vm.page - 1) * _vm.limit) + " ")];
      }
    }, {
      key: `item.name`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          on: {
            "click": function ($event) {
              return _vm.$router.push(`/console/product/${item._id}`);
            }
          }
        }, [_c('button', [_vm._v(_vm._s(item.name))])])];
      }
    }, {
      key: `item.thumb`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-row', [item.thumb ? _c('v-img', {
          attrs: {
            "src": item.thumb.src,
            "width": "54",
            "height": "54",
            "contain": ""
          }
        }) : _vm._e()], 1)];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-icon', {
          attrs: {
            "small": "",
            "title": "수정"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), false ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "title": "복사"
          },
          on: {
            "click": function ($event) {
              return _vm.copy(item);
            }
          }
        }, [_vm._v("mdi-content-copy")]) : _vm._e(), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "title": "삭제"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }], null, true)
  }), _c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-btn', {
    attrs: {
      "bottom": "",
      "color": "accent",
      "fab": "",
      "fixed": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        return _vm.create();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "480px"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("제품 등록")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "썸네일",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.file.$refs.input.click();
      }
    },
    model: {
      value: _vm.product.thumb.name,
      callback: function ($$v) {
        _vm.$set(_vm.product.thumb, "name", $$v);
      },
      expression: "product.thumb.name"
    }
  }), _c('v-file-input', {
    ref: "file",
    staticClass: "d-none",
    attrs: {
      "accept": ".pdf,.xlsx,.jpg,.png"
    },
    on: {
      "change": _vm.change
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "제품명",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.name,
      callback: function ($$v) {
        _vm.$set(_vm.product, "name", $$v);
      },
      expression: "product.name"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "식품유형",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.type,
      callback: function ($$v) {
        _vm.$set(_vm.product, "type", $$v);
      },
      expression: "product.type"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "내포장재질",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.material,
      callback: function ($$v) {
        _vm.$set(_vm.product, "material", $$v);
      },
      expression: "product.material"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "red"
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }