<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">제품 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                        <v-row class="mt-3">
                            <v-col cols="6" md="3" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="기간별" hide-details persistent-placeholder></v-select>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="(page = 1) && search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-data-table :items="products" :headers="productHeaders" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template #[`item.no`]="{ item }">
                    {{ (totalCount - products.indexOf(item))  - (page - 1) * limit }}
                </template>
				<template #[`item.name`]="{ item }">
                    <div @click="$router.push(`/console/product/${item._id}`)">
						<button>{{ item.name }}</button>
					</div>
                </template>
                <template #[`item.thumb`]="{ item }">
                    <v-row>
                        <v-img v-if="item.thumb" :src="item.thumb.src" width="54" height="54" contain></v-img>
                    </v-row>
                </template>
                <template #[`item.actions`]="{item}">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon v-if="false" small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

            <v-btn bottom color="accent" fab fixed right @click="create()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
		<v-dialog v-model="dialog" max-width="480px">
			<v-card>
				<v-card-title class="subtitle-1">제품 등록</v-card-title>
				<v-card-text>
					<v-text-field v-model="product.thumb.name" label="썸네일" persistent-placeholder hide-details readonly @click="$refs.file.$refs.input.click()"></v-text-field>
					<v-file-input accept=".pdf,.xlsx,.jpg,.png" class="d-none" ref="file" @change="change"></v-file-input>
					<v-text-field v-model="product.name" label="제품명" persistent-placeholder hide-details class="mt-4"></v-text-field>
					<v-text-field v-model="product.type" label="식품유형" persistent-placeholder hide-details class="mt-4"></v-text-field>
					<v-text-field v-model="product.material" label="내포장재질" persistent-placeholder hide-details class="mt-4"></v-text-field>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="red" @click="dialog = false">취소</v-btn>
					<v-btn color="primary" @click="save">저장</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </v-layout>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        VDateField,
    },
    data() {
        return {
			dialog: false,
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            category: null,
            filter: {
                code: this.$route.query.code,
                category: this.$route.query.category,
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,
            totalCount: null,

            products: [],
			product: {
				thumb: {src: null, name: null},
				name: null,
				type: null,
				material: null
			},

            searchKeys: [
                { text: "전체", value: null },
                { text: "제품명", value: "name" },
            ],
            searchKey: null,
            searchValue: null,

			productHeaders: [
				{ text: "no", align: "center", value: "no" },
				{ text: "썸네일", align: "center", value: "thumb" },
				{ text: "제품명", align: "center", value: "name" },
				{ text: "식품유형", align: "center", value: "type" },
				{ text: "내포장재질", align: "center", value: "material" },
				{ text: "Actions", align: "center", value: "actions" },
			],
        };
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
    methods: {
        init() {
            this.search(false);
        },
		change(file) {
			this.upload(file)
		},
		async upload(file) {
            let { src, name } = await api.resource.post(file, `/res/product`);
            this.$set(this.product, "thumb", { src, name })
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, products } = await api.console.products.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.totalCount = summary.totalCount
                this.products = products;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create() {
            this.dialog = true;
        },
        edit(product) {
            this.product = product;
			this.dialog = true;
        },
		async save() {
			let { product } = this.product._id ? await api.console.products.put(this.product) : await api.console.products.post(this.product);
			alert("제품이 등록되었습니다.")
			this.dialog = false;
			this.init()
		},
        async remove(product) {
            try {
                if (confirm("게시글을 삭제하시겠습니까?")) {
                    await api.console.products.delete(product);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>
